<template>
  <div
    style="margin-top: 0px"

    @click="removeLists"
  >
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab
          :title="$t('EmployeePayment')"
          @click="employeePayment()"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("EmployeePayment") }}</strong>
          </template>
          <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
              {{ $t("EmployeePayment") }}
            </p> -->
          <div>
            <div
              style="display: flex; margin-top: 1%"
            >
              <div
                class="table__header"
                :style="
                  isactive == true
                    ? 'justify-content: space-between; margin-bottom: -20px;'
                    : 'justify-content: flex-end; margin-bottom: 15px'
                "
              >
                <div
                  style="display: flex"
                  class="con"
                >
                  <div>
                    <div
                      class="black"
                      style="margin-bottom: 7%;"
                    >
                      <b-icon-search
                        v-if="searchUser == ''"
                        class="search1"
                        style="position: absolute; left: 5px; top: 12px"
                      />
                      <b-icon-x-circle
                        v-else-if="searchUser != '' && !noSearch"
                        class="search1"
                        style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                        @click="searchUser = ''"
                      />
                      <input
                        v-model="searchUser"
                        autocomplete="off"
                        type="text"
                        name="search-box"
                        :placeholder="$t('FullName')"
                        :style="
                          getFilteredActiveUsers.length > 0 ||
                            (getFilteredInactiveUsers.length > 0 && noSearch)
                            ? 'border-radius: 8px 8px 0px 0px'
                            : ''
                        "
                        @keyup="inputChanged"
                        @keydown.down="onArrowDown"
                        @keydown.up="onArrowUp"
                      >
                    </div>
                    <div
                      v-if="
                        (getFilteredActiveUsers.length > 0 || getFilteredInactiveUsers.length > 0) &&
                          noSearch
                      "
                      ref="scrollContainer"
                      class="dropdrop"
                    >
                      <div
                        v-for="(result, index) in getFilteredActiveUsers.length > 0
                          ? getFilteredActiveUsers
                          : getFilteredInactiveUsers"
                        :key="index"
                        ref="options"
                        style="cursor: pointer"
                        class="search-item"
                        :class="{ 'is-active': index === arrowCounter }"
                        @click="
                          searchByOn(result);
                          noSearch = false;
                        "
                      >
                        <p style="margin: 0">
                          {{ result.fullName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <b-form-group
                  id="input-group-2"
                  :label="$t('VacationEmployeeT')"
                  label-for="input-2"
                  style="width: 18%;"
                >
                  <vue-select
                    v-model="employeeType"
                    required
                    :options="types"
                    label="label"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group> -->
            </div>
            <div
              style="width: 100%; padding-top: 16px; max-height: 73vh; overflow-y: scroll;"
              class="scroll"
            >
              <table
                class="team_table"
              >
                <thead style="position: sticky; top: -17px">
                  <tr>
                    <th>
                      {{ $t("No") }}
                    </th>
                    <th>
                      {{ $t("FirstName") }}
                    </th>
                    <th>
                      {{ $t("LastName") }}
                    </th>
                    <th
                      v-if="isactive == true"
                      @click="sortByRole"
                    >
                      {{ $t("Role") }}
                      <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                      <b-icon-sort-alpha-down v-else />
                    </th>
                    <th
                      v-else
                      @click="sortByRoleI"
                    >
                      {{ $t("Role") }}
                      <b-icon-sort-alpha-down v-if="sortedRI == false" />
                      <b-icon-sort-alpha-up-alt v-else />
                    </th>
                    <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                        {{ $t("VacationEmployeeT") }}
                      </th> -->
                    <th>
                      {{ $t("Salary") }}
                    </th>
                    <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                        {{ $t("Filiale") }}
                      </th> -->
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(user, index) in filteredUsers"
                    :key="index"
                    v-b-tooltip.hover.bottom
                    style="
                      border-bottom: 1px solid #dcdfe6;
                      color: #606266;
                      text-align: left;
                      cursor: pointer;
                    "
                    :title="getLanguage == 'en' ? `${$t('ClickFor')+' '+ user.firstName +' '+ user.lastName +' '+ $t('PaymentDetails')}` : `${$t('PaymentFor') +' '+ user.firstName +' '+ user.lastName}`"
                    @click="matProps(user), showSameId(user.userId) , loadFeedbackk(user)"
                  >
                    <td>
                      {{ getTotalItemsForUsers - (page - 1) * pageSize - index }}.
                    </td>
                    <td>
                      {{ user.firstName }}
                    </td>
                    <td>
                      {{ user.lastName }}
                    </td>
                    <td>
                      <button id="button">
                        {{ $t(user.role) }}
                      </button>
                    </td>
                    <!-- <td style="padding: 9px">
                        {{ user.employeeType }}
                      </td> -->
                    <td>
                      {{ numbersWithcomma(user.salary) }}€
                    </td>
                    <!-- <td style="padding: 9px">
                        {{ user.storeName }}
                      </td> -->

                  </tr>
                  <div style="margin: 10px;">
                    <button
                      v-if="selectedId != null"
                      class="button"
                      style="font-size: 16px;"
                      @click="showAll"
                    >
                      {{ $t("ViewAll") }}
                    </button>
                  </div>
                </tbody>
              </table>

            </div>
            <div>
              <b-pagination
                v-if="tabIndex == 0 && getTotalItemsForUsers > 15"
                v-model="page"
                :total-rows="getTotalItemsForUsers"
                :per-page="pageSize"
                first-number
                last-number
                :hidden="selectedId"
                align="fill"
                prev-class="prev-itemi"
                next-class="next-itemi"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <span>{{ $t("Previous") }}</span>
                </template>
                <template #next-text>
                  <span>{{ $t("Next") }}</span>
                </template>
              </b-pagination>
            </div>
          </div>
        </b-tab>
        <b-tab
          :title="$t('MonthlyOv')"
          @click=" showAll(); monthly()"
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MonthlyOv") }}</strong>
          </template>
          <!-- <p style="font-size: 32px; font-weight: bold; border-bottom: 1px solid lightgray;">
              {{ $t("Monthly overview") }}
            </p> -->
          <div>
            <div
              style="display: flex; margin-top: 1%"
            >
              <!-- <b-form-group
                  id="input-group-2"
                  :label="$t('VacationEmployeeT')"
                  label-for="input-2"
                  style="width: 18%;"
                >
                  <vue-select
                    v-model="employeeType"
                    required
                    :options="types"
                    :clearable="false"
                    label="label"
                    :placeholder="$t('Select')"
                    aria-describedby="input-1-live-feedback"
                  />
                </b-form-group> -->
              <div style="display: flex; width: 100%; margin-left: 10px;">
                <b-form-group
                  :label="$t('SelectD')"
                >
                  <div class="year">
                    <button
                      class="nextmonth"
                      @click="previousMonth"
                    >
                      <b-icon-arrow-left />
                    </button>
                    <date-picker
                      v-model="selectedDate"
                      value-type="format"
                      class="form-control"
                      format="MM-YYYY"
                      type="month"
                      :placeholder="$t('SelectD')"
                      style="width: 193px;"
                    />
                    <button
                      class="nextmonth"
                      style="margin-left: 5px;"
                      @click="nextMonth"
                    >
                      <b-icon-arrow-right />
                    </button>
                  </div>
                </b-form-group>
                <!-- <b-form-group
                    id="input-group-8"
                    :label="$t('Year')"
                    style="width:50%;  margin-right: 10px;"
                    label-for="input-25"
                  >
                    <vue-select
                      id="input-5"
                      v-model="year"
                      :clearable="false"
                      required
                      :options="yearsArray"
                      :placeholder="$t('Select')"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group>
                  <b-form-group
                    id="input-group-8"
                    :label="$t('Month')"
                    style="width:50%"
                    label-for="input-25"
                  >
                    <vue-select
                      id="input-5"
                      v-model="monthh"
                      :reduce="(e) => e.value"
                      required
                      :options="months"
                      :clearable="false"
                      label="name"
                      :placeholder="$t('Select')"
                      aria-describedby="input-1-live-feedback"
                    />
                  </b-form-group> -->
                <div style="display: flex; align-items: center; margin: 0 15px;margin-top: 30px; width: 100%;">
                  <b-form-radio
                    v-model="selection"
                    :value="true"
                  >
                    <span style="margin: 5px">

                      {{ $t("Paid") }}
                    </span>

                  </b-form-radio>
                  <b-form-radio
                    v-model="selection"
                    :value="false"
                    style="margin-left: 10px"
                  >
                    <span
                      style="margin: 5px"
                    >

                      {{ $t("NotPaid") }}
                    </span>

                  </b-form-radio>
                </div>
              </div>
            </div>
            <div
              style="width: 100%; padding-top: 16px; height: 70vh; overflow-y: scroll;"
              class="scroll"
            >
              <table
                class="team_table"
              >
                <thead style="position: sticky; top: -17px">
                  <tr>
                    <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                        {{ $t("Date") }}
                      </th> -->
                    <th>
                      {{ $t("No") }}
                    </th>
                    <th>
                      {{ $t("FirstName") }}
                    </th>
                    <th>
                      {{ $t("LastName") }}
                    </th>
                    <th
                      v-if="isactive == true"
                      @click="sortByRole"
                    >
                      {{ $t("Role") }}
                      <b-icon-sort-alpha-up-alt v-if="sortedR == false" />
                      <b-icon-sort-alpha-down v-else />
                    </th>
                    <th
                      v-else
                      @click="sortByRoleI"
                    >
                      {{ $t("Role") }}
                      <b-icon-sort-alpha-down v-if="sortedRI == false" />
                      <b-icon-sort-alpha-up-alt v-else />
                    </th>
                    <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                        {{ $t("VacationEmployeeT") }}
                      </th> -->
                    <th>
                      {{ $t("EmployeeSalary") }}
                    </th>
                    <th>
                      {{ $t("Paid1") }}
                    </th>
                    <!-- <th style="padding: 10px; border-radius: 0px 0px 0px 0px; text-align: center">
                        {{ $t("Filiale") }}
                      </th> -->
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(user, index) in getMonthlyOverviewPaymentPagination"
                    :key="index"
                    style="
                text-align: left;
                cursor: pointer;
              "
                  >
                    <!-- <td style="padding: 9px">
                        {{ monthToString(user.month) }} - {{ user.year }}
                      </td> -->
                    <td>
                      {{ getTotalItemsForUsers_2 - (page - 1) * pageSize - index }}.
                    </td>
                    <td>
                      {{ user.firstName }}
                    </td>
                    <td>
                      {{ user.lastName }}
                    </td>
                    <td>
                      <button id="button">
                        {{ $t(user.role) }}
                      </button>
                    </td>
                    <!-- <td style="padding: 9px">
                        {{ user.employeeType }}
                      </td> -->
                    <td>
                      {{ user.salary }}€
                    </td>
                    <td>
                      {{ numbersWithcomma(user.paidAmount) }}€
                    </td>
                    <!-- <td style="padding: 9px">
                        {{ user.storeName }}
                      </td> -->

                  </tr>
                  <div style="margin: 10px;">
                    <b-button
                      v-if="selectedId != null"
                      class="viewAllBtn"
                      @click="showAll"
                    >
                      {{ $t("ViewAll") }}
                    </b-button>
                  </div>
                </tbody>
              </table>
            </div>
            <div>
              <b-pagination
                v-if="tabIndex == 1 && getTotalItemsForUsers_2 > 15"
                v-model="page"
                :total-rows="getTotalItemsForUsers_2"
                :per-page="pageSize"
                first-number
                last-number
                :hidden="selectedId"
                align="fill"
                prev-class="prev-itemi"
                next-class="next-itemi"
                class="mt-1 mb-0"
              >
                <template #prev-text>
                  <span>{{ $t("Previous") }}</span>
                </template>
                <template #next-text>
                  <span>{{ $t("Next") }}</span>
                </template>
              </b-pagination>

            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
  name: 'EmployeePaymentListing',
  components: {
    DatePicker,
  },
  props: {
    users: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedDate: '',
      userObj: {},
      showOtherComponent: false,
      selection: true,
      tabIndex: 0,
      isactive: true,
      searchUser: '',
      employeeType: 'Internal',
      employeeType2: null,
      noSearch: false,
      sortedR: false,
      sortedRI: false,
      selectedId: null,
      deactivateItemId: {},
      activateItemId: {},
      totalItems: '',
      page: 1,
      pageSize: 15,
      types: [
        {
          value: 'Internal',
          label: this.$t('Internal'),
        },
        {
          value: 'External',
          label: this.$t('External'),
        },
      ],
      status: true,
      arrowCounter: 0,
      enterPressed: false,
      year: moment().format('YYYY'),
      employeeId: null,
      months: [
        {
          value: 1,
          name: `${this.$t('January')}`,
        },
        {
          value: 2,
          name: `${this.$t('February')}`,
        },
        {
          value: 3,
          name: `${this.$t('March')}`,
        },
        {
          value: 4,
          name: `${this.$t('April')}`,
        },
        {
          value: 5,
          name: `${this.$t('May')}`,
        },
        {
          value: 6,
          name: `${this.$t('June')}`,
        },
        {
          value: 7,
          name: `${this.$t('July')}`,
        },
        {
          value: 8,
          name: `${this.$t('August')}`,
        },
        {
          value: 9,
          name: `${this.$t('September')}`,
        },
        {
          value: 10,
          name: `${this.$t('October')}`,
        },
        {
          value: 11,
          name: `${this.$t('November')}`,
        },
        {
          value: 12,
          name: `${this.$t('December')}`,
        },
      ],
      selectedYear: null,
      monthh: moment().format('MMMM'),
      yearsArray: [2020, 2021, 2022, 2023],
    };
  },
  computed: {
    ...mapGetters([
      'getEmployeePaymentPagination',
      'getInactiveUsers',
      'getFilteredActiveUsers',
      'getFilteredInactiveUsers',
      'getTotalItemsForUsers',
      'getTotalItemsForUsers_2',
      'getTotalItemsForInActiveUsers',
      'getFeedback',
      'getMonthlyOverviewPaymentPagination',
      'getLanguage',
    ]),
    filteredUsers() {
      // filter the users array to only show rows with the same id as the selected id
      return this.selectedId
        ? this.getEmployeePaymentPagination.filter((getEmployeePaymentPagination) => {
          return getEmployeePaymentPagination.userId === this.selectedId;
        })
        : this.getEmployeePaymentPagination;
    },
  },
  watch: {
    selectedDate(newDate) {
      const [month, year] = newDate.split('-'); // Extract month and year
      this.loadMonthlyOverviewPaymentPagination({
        year,
        month,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: this.selection,
        pageNumber: this.page,
        pageSize: 15,
      })
    },
    page(value) {
      if (this.tabIndex == 0) {
        this.loadEmployeePaymentPagination({
          firstName: this.searchUser == '' ? null : this.searchUser,
          employeeType: this.employeeType ? null : this.employeeType.value,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      } else {
        const [month, year] = this.selectedDate.split('-');
        this.loadMonthlyOverviewPaymentPagination({
          year,
          month,
          employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
          filterPaidEmployees: this.selection,
          pageNumber: value,
          pageSize: 15,
        })
      }
    },
    year(value) {
      this.loadMonthlyOverviewPaymentPagination({
        year: value,
        month: this.monthh ? moment().format('MM') : this.monthh.value,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: this.selection,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    searchUser(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.selectedId = null;
        if (value == '') {
          this.removeLists();
          if (this.isactive) {
            this.loadEmployeePaymentPagination({
              firstName: value || null,
              pageNumber: this.page,
              pageSize: this.pageSize,
            });
          } else if (this.enterPressed) {
          // eslint-disable-next-line no-useless-return
            return;
          } else {
            this.getInactiveUsersPagination({
              status: false,
              pageNumber: this.page,
              pageSize: this.pageSize,
              fullName: value || null,
            });
          }
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else if (this.isactive) {
          this.filterActiveUsersByName(value);
        } else {
          this.filterInactiveUsersByName(value);
        }
        this.noSearch = true;
      }, 500)
    },
    employeeType(value) {
      if (this.tabIndex == 0) {
        this.loadEmployeePaymentPagination({
          firstName: this.searchUser == '' ? null : this.searchUser,
          employeeType: value ? value.value : null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        });
      } else {
        const temp = this.monthh ? moment().format('MM') : this.monthh.value;
        this.loadMonthlyOverviewPaymentPagination({
          year: this.year,
          month: temp,
          employeeType: value ? value.value : null,
          filterPaidEmployees: this.selection,
          pageNumber: 1,
          pageSize: 15,
        })
      }
    },
    monthh(value) {
      this.loadMonthlyOverviewPaymentPagination({
        year: this.year,
        month: value,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: true,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    selection(value) {
      const temp = this.monthh ? moment().format('MM') : this.monthh.value;
      this.loadMonthlyOverviewPaymentPagination({
        year: this.year,
        month: temp,
        employeeType: this.employeeType.value == null ? this.employeeType : this.employeeType.value,
        filterPaidEmployees: value,
        pageNumber: 1,
        pageSize: 15,
      })
      // this.loadMonthlyOverviewPaymentPagination({
      //   year: this.year,
      //   month: this.monthh == '' ? moment().format('MM') : this.monthh.value,
      //   employeeType: this.employeeType ? this.employeeType.value : null,
      //   filterPaidEmployees: value,
      //   pageNumber: 1,
      //   pageSize: 15,
      // })
    },
  },
  mounted() {
    this.selectedDate = moment().format('MM-YYYY');
    this.fetch();
  },
  methods: {
    ...mapActions([
      'loadEmployeePaymentPagination',
      'getInactiveUsersPagination',
      'filterActiveUsersByName',
      'resetFilteredActiveUsers',
      'filterInactiveUsersByName',
      'resetFilteredInactiveUsers',
      'changeLoadingtoTrue',
      'loadVacationRequests',
      'assignEmployeeToStoree',
      'leaveRequest',
      'remainingDaysOfEmployee',
      'filterActiveUsersByName',
      'loadPayments',
      'loadEmployeePaymentPagination',
      'loadMonthlyOverviewPaymentPagination',
    ]),
    numbersWithcomma(value) {
      const options = {
        style: 'decimal', // Other options: 'currency', 'percent', etc.
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return value.toLocaleString('en-US', options);
    },
    monthly() {
      this.page = 1
      const temp = moment().format('MM')
      this.loadMonthlyOverviewPaymentPagination({
        year: moment().format('YYYY'),
        month: temp,
        employeeType: 'Internal',
        filterPaidEmployees: true,
        pageNumber: 1,
        pageSize: 15,
      })
    },
    previousMonth() {
      this.selectedDate = moment(this.selectedDate, 'MM-YYYY').subtract(1, 'month').format('MM-YYYY');
    },
    nextMonth() {
      this.selectedDate = moment(this.selectedDate, 'MM-YYYY').add(1, 'month').format('MM-YYYY');
    },
    employeePayment() {
      this.page = 1
      this.loadEmployeePaymentPagination({
        firstName: null,
        lastName: null,
        employeeType: null,
        pageNumber: this.page,
        pageSize: this.pageSize,
      })
    },
    async fetch() {
      try {
        // this.changeLoadingtoTrue(false);

        await this.loadEmployeePaymentPagination({
          firstName: null,
          lastName: null,
          employeeType: null,
          pageNumber: this.page,
          pageSize: this.pageSize,
        }).then(() => {
          // this.changeLoadingtoTrue(true);
        });
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false);
      }
    },
    matProps(users) {
      this.$emit('click', users);
      this.classNameCh = users.userId;
      this.$emit('show-the-component');
    },
    loadRemainingDays(value) {
      const currentYear = new Date().getFullYear();
      this.remainingDaysOfEmployee({ employeeUserId: value, year: currentYear });
    },
    monthToString(value) {
      if (value == 1) {
        return 'Janar'
      } if (value == 2) {
        return 'February'
      } if (value == 3) {
        return 'March'
      } if (value == 4) {
        return 'April'
      } if (value == 5) {
        return 'May'
      } if (value == 6) {
        return 'June'
      } if (value == 7) {
        return 'July'
      } if (value == 8) {
        return 'August'
      } if (value == 9) {
        return 'September'
      } if (value == 10) {
        return 'Octomber'
      } if (value == 11) {
        return 'November'
      } if (value == 12) {
        return 'December'
      }
      return ''
    },
    async assignEmployeeToFiliale(obj) {
      await this.assignEmployeeToStoree({
        object: obj,
        successCallback: () => {
          this.loadEmployeePaymentPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        },
      });
    },
    showSameId(id) {
      // set the selected id to the id of the clicked row
      this.selectedId = id;
    },
    loadFeedbackk(id) {
      // console.log('testt', id.userId)
      const currentYear = new Date().getFullYear();
      this.loadPayments({ userId: id.userId, year: currentYear })
    },
    showAll() {
      // set the selected id to null to show all rows
      this.selectedId = null;
      this.$emit('show-other-component');
    },
    dateModified(xdate) {
      return moment(xdate).format('DD/MM/YYYY');
    },
    onArrowDown(ev) {
      ev.preventDefault();
      if (this.arrowCounter < this.getFilteredActiveUsers.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault();
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling();
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') {
        return;
      }
      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true;
        this.searchByOn(this.getFilteredActiveUsers[this.arrowCounter]);
        this.removeLists();
        this.arrowCounter = 0;
        setTimeout(() => {
          this.enterPressed = false;
        }, 1000);
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      // console.log(liH);
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    sortByRole() {
      if (this.sortedR == false) {
        this.getEmployeePaymentPagination.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedR = true;
      } else {
        this.getEmployeePaymentPagination.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedR = false;
      }
    },
    sortByRoleI() {
      if (this.sortedRI == false) {
        this.getInactiveUsers.sort((a, b) => {
          return a.role.localeCompare(b.role);
        });
        this.sortedRI = true;
      } else {
        this.getInactiveUsers.sort((a, b) => {
          return a.role > b.role ? -1 : 1;
        });
        this.sortedRI = false;
      }
    },
    nameToShow(value) {
      if (value > 0) {
        return value
      }
      return '0'
    },
    async searchByOn(value) {
      const fullName = value.fullName;
      const spaceIndex = fullName.indexOf(' ');
      let firstName; let
        lastName;

      if (spaceIndex !== -1) {
        // Split the fullName into firstName and lastName
        firstName = fullName.slice(0, spaceIndex);
        lastName = fullName.slice(spaceIndex + 1);
      } else {
        // If there is no space, assume the entire name is the firstName
        firstName = fullName;
        lastName = ''; // You can set this to an empty string or handle it differently
      }

      this.searchUser = fullName;
      this.noSearch = false;

      await this.loadEmployeePaymentPagination({
        firstName,
        lastName,
        pageNumber: this.page,
        pageSize: this.pageSize,
      });
    },
    removeLists() {
      this.noSearch = false;
      this.resetFilteredActiveUsers();
      this.resetFilteredInactiveUsers();
    },
  },
};
</script>

<style scoped>

.team_table td {
  padding: 16px 9px !important;
}

.team_table th {
  text-align: left;
}
/* .button {
  width: 195px;
} */

.viewAllBtn{
  background: #FF274F;
  border: none;
}
.addEmployeeButton {
  width: 210px;
}

#button {
  width: fit-content;
  height: 22px;
  background: #EAEDFB;
  color: #242F6E;
  font-weight: 500;
  font-size: 12px;
  border: 1px solid #242F6E;
  border-radius: 1000px;
  cursor: default;
}

@media screen and (max-width: 1600px) {
  .scroll {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1110px) {
  .button {
    margin-right: 10px;
    font-size: 12px;
    height: 36px;
    width: 150px;
  }
}
.nextmonth {
    width: fit-content !important;
    height: 35px;
    display: flex;
    margin-right: 5px;
    border-radius: 6px !important;
    justify-content: center;
    border: #c3d0dd 1px solid;
    background-color: #e9ecef;
    align-items: center;
}
.year {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dropdrop::-webkit-scrollbar {
  display: none;
}
.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;;
  z-index: 3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

@media screen and (max-width: 763px) {
  .con {
    flex-wrap: wrap;
  }

  .active-inactive-con {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .button {
    height: auto;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
